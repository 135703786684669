"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrapList = exports.transformItemsIntoListItemWrapper = void 0;
function transformItemsIntoListItemWrapper(items) {
    return items.map((item) => {
        return {
            loading: false,
            id: item.id || 'unknown',
            listItem: item,
        };
    });
}
exports.transformItemsIntoListItemWrapper = transformItemsIntoListItemWrapper;
function wrapList(list) {
    return {
        loading: false,
        id: list.id || '',
        list,
    };
}
exports.wrapList = wrapList;
