"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ITEM_TYPES = void 0;
// Warning ITEM_TYPES is duplicated 3 times, please keep them synchronized
exports.ITEM_TYPES = {
    THING: 'https://schema.org/Thing',
    MOVIE: 'https://schema.org/Movie',
    BOOK: 'https://schema.org/Book',
    PRODUCT: 'https://schema.org/Product',
    RECIPE: 'https://schema.org/Recipe',
    WEBPAGE: 'https://schema.org/WebPage',
    DEFAULT: 'https://schema.org/WebPage',
    RESTAURANT: 'https://schema.org/WebPage',
    LIST: 'https://alistigo.com/types/List',
    TAG: 'https://alistigo.com/types/Tag',
};
