
    var doc = {"kind":"Document","definitions":[{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"Position"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"TOP_LEFT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"TOP_RIGHT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"BOTTOM_LEFT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"BOTTOM_RIGHT"},"directives":[]}]},{"kind":"ObjectTypeDefinition","name":{"kind":"Name","value":"ClientApplication"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"visible"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"debug"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"position"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Position"}}},"directives":[]}]},{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Query"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"app"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ClientApplication"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"isUIInitialized"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}},"directives":[]}]}],"loc":{"start":0,"end":234}};
    doc.loc.source = {"body":"enum Position {\n  TOP_LEFT\n  TOP_RIGHT\n  BOTTOM_LEFT\n  BOTTOM_RIGHT\n}\n\ntype ClientApplication {\n  visible: Boolean!\n  debug: Boolean!\n  position: Position!\n}\n\nextend type Query {\n  app: ClientApplication!\n  isUIInitialized: Boolean\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
