"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserType = void 0;
var UserType;
(function (UserType) {
    UserType["identity"] = "identity";
    UserType["anonymous"] = "anonymous";
    UserType["registered"] = "registered";
    UserType["bot"] = "bot";
})(UserType || (exports.UserType = UserType = {}));
