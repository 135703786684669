"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType = exports.EventDomain = void 0;
var EventDomain;
(function (EventDomain) {
    EventDomain["item"] = "item";
})(EventDomain || (exports.EventDomain = EventDomain = {}));
var EventType;
(function (EventType) {
    EventType["created"] = "created";
})(EventType || (exports.EventType = EventType = {}));
