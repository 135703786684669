"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationType = exports.KeyType = void 0;
var KeyType;
(function (KeyType) {
    KeyType["api"] = "api";
    KeyType["widget"] = "widget";
})(KeyType || (exports.KeyType = KeyType = {}));
var IntegrationType;
(function (IntegrationType) {
    IntegrationType["shopify"] = "shopify";
    IntegrationType["generic"] = "generic";
})(IntegrationType || (exports.IntegrationType = IntegrationType = {}));
